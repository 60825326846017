/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: "Kanit";
    src: url("../src/assets/font/Kanit/Kanit-Regular.ttf");
}

* {
    font-family: 'Kanit', sans-serif;
}

.pointer:hover {
    cursor: pointer;
}

.click:active {
    opacity: 0.7;
}

.atk-detail-modal {
    --height: 300px;
    --width: 400px;
    --border-radius: 20px;
}

.reset-password-modal {
    --height: 270px;
    --width: 500px;
    --border-radius: 70px;
}

.health-history-modal {
    --border-radius: 20px;
}


.add-factory-modal {}

.small-modal {
    &::part(content) {
        border-radius: 30px !important;
        width: 599px;
        height: 340px;
    }
}

.middle-modal {
    &::part(content) {
        border-radius: 30px !important;
        width: 599px;
        height: 400px;
    }
}

.s-modal {
    &::part(content) {
        border-radius: 30px !important;
        width: 599px;
        height: 400px;
    }
}

.m-modal {
    &::part(content) {
        border-radius: 30px !important;
        width: 599px;
        height: 550px;
    }
}

.l-modal {
    &::part(content) {
        border-radius: 30px !important;
        width: 650px;
        height: 600px;
    }
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #FFFFFF;
    border: 1px solid #CECEFE;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #CECEFE;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #CECEFE;
}

.md-range-filter .md-header {
    text-align: center;
    margin: 0;
}

.mbsc-ios.mbsc-datepicker-inline {
    border: none;
}

